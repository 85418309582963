import React, { useRef, useEffect } from 'react'

import Movie from '../MoviePageById/Movie'

function MoviePopup(props) {

  function useOutside(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if(ref.current && !ref.current.contains(event.target)) {
          props.setShowPopup(false);
        }
      }
  
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref])
  }

  const wrapperRef = useRef(null);
  useOutside(wrapperRef);

  return(
    <div style={{display: `${props.showPopup ? 'block' : 'none'}`}} ref={wrapperRef} className="movie__popup">
      <Movie data={props.data}/>
    </div>
  )
}

export default MoviePopup;
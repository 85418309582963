import React, { useState } from 'react'
import jwt_decode from 'jwt-decode'

import './MoviePage.scss'
import * as API from '../../../api/movies-api'
import Movies from './Movies'

function MoviePage(props) {

  const [title, setTitle] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [rating, setRating] = useState("");
  const [description, setDescription] = useState("");

  function add(event) {
    event.preventDefault()
    let element = {
      title: title,
      imageUrl: imageUrl,
      rating: rating,
      description: description
    }
    API.save(element, result => {
      if(result != null) {
        if(result.status === 401) {
          setTimeout(() => { API.save(element, res => {
            if(res != null && res.status !== 401) {
              window.location.reload();
            }
          }) }, 1000);
        } else window.location.reload();
      } else window.location.reload();
    });
  }

  const showStyle = {
    display: 'block'
  }

  const hideStyle = {
    display: 'none'
  }

  return(
    <>

      <div className="content__container">
        <div className="content__header__container">
          <h2 className="title">Movies</h2>
        </div>
      
        <div className="movies">

          <div className="movie__flip-card" style={props.showAdmin && localStorage.getItem('accessToken') != null && jwt_decode(localStorage.getItem('accessToken')).admin ? showStyle : hideStyle}>
            <div className="movie__flip-card__inner">
              <div className="movie__flip-card--front">
                <div style={props.showAdmin ? showStyle : hideStyle} className="admin-box"><i className="fas fa-plus"></i></div>
              </div>
              <div className="movie__flip-card--back">
                <h3>ADD MOVIE</h3>
                <div className="label-input-pair">
                  <label>TITLE</label>
                  <input onChange={event => setTitle(event.target.value)} placeholder="Lord of the Rings" type="text"/>
                </div>
                <div className="label-input-pair">
                  <label>IMAGE URL</label>
                  <input onChange={event => setImageUrl(event.target.value)} placeholder="https://www.imgur.com" type="text"/>
                </div>
                <div className="label-input-pair">
                  <label>RATING</label>
                  <input onChange={event => setRating(event.target.value)} placeholder="8.7" type="text"/>
                </div>
                <div className="label-textarea-pair">
                  <label>DESCRIPTION</label>
                  <textarea onChange={event => setDescription(event.target.value)} placeholder="In a land far, far away..."></textarea>
                </div>
                <button onClick={event => {add(event)}}>ADD</button>
              </div>
            </div>
          </div>

          <Movies showAdmin={props.showAdmin}/>
            
        </div>
      </div>
    </>
  )
}

export default MoviePage;
import React, { useState, useEffect } from 'react'
import { useCookies } from 'react-cookie'

import RestClient from '../api/rest-client'
import Header from './Header/Header'
import Content from './Content/Content'
import './App.scss'

function App() {

  const [loggedIn, setLoggedIn] = useState(false);
  const [cookies, setCookie, removeCookie] = useCookies(['refreshToken', 'shoppingCart']);
  const [showAdmin, setShowAdmin] = useState(false);

  useEffect(() => {
    if(sessionStorage.getItem('showAdmin') !== null) {
      if(sessionStorage.getItem('showAdmin') === 'true') {
        setShowAdmin(true);
      } else if(sessionStorage.getItem('showAdmin') === 'false') {
        setShowAdmin(false);
      }
    } else {
      setShowAdmin(false);
    }
  }, [])

  useEffect(() => {
    if(showAdmin === true) {
      sessionStorage.setItem('showAdmin', 'true');
    } else if(showAdmin === false) {
      sessionStorage.setItem('showAdmin', 'false');
    }
  }, [showAdmin])

  function toggleShowAdmin() {
    if(showAdmin === true) {
      setShowAdmin(false);
    } else if (showAdmin === false) { 
      setShowAdmin(true);
    }
  }

  function setLoggedInFunction(value) {
    setLoggedIn(value);
  }

  useEffect(() => {
    if(cookies.refreshToken != null) setLoggedIn(true);
    RestClient.setRefreshToken(cookies.refreshToken);
  }, [cookies.refreshToken])

  function setRefreshToken(newToken) {
    setCookie('refreshToken', newToken, { path: '/', maxAge: 7776000});
  }

  function removeRefreshToken() {
    removeCookie('refreshToken', { path: '/', maxAge: 7776000});
  }

  return (
    <>
      <Header 
        loggedIn = { loggedIn } 
        setLoggedIn = { setLoggedInFunction }
        refreshToken = { cookies.refreshToken }
        removeRefreshToken = { removeRefreshToken }
        showAdmin = { showAdmin }
        toggleShowAdmin = { toggleShowAdmin }
      />
      <Content 
        loggedIn = { loggedIn }
        setLoggedIn = { setLoggedIn }
        refreshToken = { cookies.refreshToken }
        setRefreshToken = { setRefreshToken }
        showAdmin = { showAdmin }    
      />
      <div className="snow">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1100">
          <g fill="rgb(255, 0, 0)" fillOpacity=".15" transform="translate(55 42)">
            <g id="snow-bottom-layer">
              <circle className="circle__medium" cx="6" cy="1009.5"/>
              <circle className="circle__medium" cx="138" cy="1110.5"/>
              <circle className="circle__medium" cx="398" cy="1055.5"/>
              <circle className="circle__medium" cx="719" cy="1284.5"/>
              <circle className="circle__medium" cx="760" cy="1155.5"/>
              <circle className="circle__medium" cx="635" cy="1459.5"/>
              <circle className="circle__medium" cx="478" cy="1335.5"/>
              <circle className="circle__medium" cx="322" cy="1414.5"/>
              <circle className="circle__medium" cx="247" cy="1234.5"/>
              <circle className="circle__medium" cx="154" cy="1425.5"/>
              <circle className="circle__medium" cx="731" cy="773.5"/>
              <circle className="circle__medium" cx="599" cy="874.5"/>
              <circle className="circle__medium" cx="339" cy="819.5"/>
              <circle className="circle__medium" cx="239" cy="1004.5"/>
              <circle className="circle__medium" cx="113" cy="863.5"/>
              <circle className="circle__medium" cx="102" cy="1223.5"/>
              <circle className="circle__medium" cx="395" cy="1155.5"/>
              <circle className="circle__medium" cx="826" cy="943.5"/>
              <circle className="circle__medium" cx="626" cy="1054.5"/>
              <circle className="circle__medium" cx="887" cy="1366.5"/>
              <circle className="circle__medium" cx="6" cy="241.5"/>
              <circle className="circle__medium" cx="138" cy="342.5"/>
              <circle className="circle__medium" cx="398" cy="287.5"/>
              <circle className="circle__medium" cx="719" cy="516.5"/>
              <circle className="circle__medium" cx="760" cy="387.5"/>
              <circle className="circle__medium" cx="635" cy="691.5"/>
              <circle className="circle__medium" cx="478" cy="567.5"/>
              <circle className="circle__medium" cx="322" cy="646.5"/>
              <circle className="circle__medium" cx="247" cy="466.5"/>
              <circle className="circle__medium" cx="154" cy="657.5"/>
              <circle className="circle__medium" cx="731" cy="5.5"/>
              <circle className="circle__medium" cx="599" cy="106.5"/>
              <circle className="circle__medium" cx="339" cy="51.5"/>
              <circle className="circle__medium" cx="239" cy="236.5"/>
              <circle className="circle__medium" cx="113" cy="95.5"/>
              <circle className="circle__medium" cx="102" cy="455.5"/>
              <circle className="circle__medium" cx="395" cy="387.5"/>
              <circle className="circle__medium" cx="826" cy="175.5"/>
              <circle className="circle__medium" cx="626" cy="286.5"/>
              <circle className="circle__medium" cx="887" cy="598.5"/>
            </g>
          </g>
          <g fill="rgb(255, 0, 0)" fillOpacity=".3" transform="translate(65 63)">
            <g id="snow-top-layer">
              <circle className="circle__big" cx="8" cy="776"/>
              <circle className="circle__big" cx="189" cy="925"/>
              <circle className="circle__big" cx="548" cy="844"/>
              <circle className="circle__big" cx="685" cy="1115"/>
              <circle className="circle__big" cx="858" cy="909"/>
              <circle className="circle__big" cx="874" cy="1438"/>
              <circle className="circle__big" cx="657" cy="1256"/>
              <circle className="circle__big" cx="443" cy="1372"/>
              <circle className="circle__big" cx="339" cy="1107"/>
              <circle className="circle__big" cx="24" cy="1305"/>
              <circle className="circle__big" cx="8" cy="8"/>
              <circle className="circle__big" cx="189" cy="157"/>
              <circle className="circle__big" cx="548" cy="76"/>
              <circle className="circle__big" cx="685" cy="347"/>
              <circle className="circle__big" cx="858" cy="141"/>
              <circle className="circle__big" cx="874" cy="670"/>
              <circle className="circle__big" cx="657" cy="488"/>
              <circle className="circle__big" cx="443" cy="604"/>
              <circle className="circle__big" cx="339" cy="339"/>
              <circle className="circle__big" cx="24" cy="537"/>
            </g>
          </g>
          <g fill="rgb(255,215,0)" fillOpacity=".5" transform="translate(75 73)">
            <g id="snow-middle-layer">
              <circle className="circle__small" cx="25" cy="776"/>
              <circle className="circle__small" cx="269" cy="925"/>
              <circle className="circle__small" cx="488" cy="844"/>
              <circle className="circle__small" cx="595" cy="1115"/>
              <circle className="circle__small" cx="728" cy="909"/>
              <circle className="circle__small" cx="854" cy="1438"/>
              <circle className="circle__small" cx="25" cy="1776"/>
              <circle className="circle__small" cx="269" cy="425"/>
              <circle className="circle__small" cx="488" cy="244"/>
              <circle className="circle__small" cx="595" cy="1315"/>
              <circle className="circle__small" cx="728" cy="909"/>
              <circle className="circle__small" cx="854" cy="638"/>
            </g>
          </g>
		    </svg>
      </div>
    </>
  );
}

export default App;
import React, { useState, useEffect } from 'react'

import * as API from '../../../api/movies-api'
import * as COMMENT_API from '../../../api/comment-api'
import './Movie.scss'

function Movie(props) {

  const [commentMessage, setCommentMessage] = useState("");

  function getUserId() {
    return JSON.parse(atob(localStorage.getItem('accessToken').split('.')[1])).sub
  }

  function addComment(event) {
    event.preventDefault();
    let element = {
      message: commentMessage,
      movieId: props.data.id,
      userId: getUserId()
    }
    COMMENT_API.save(element, result => {
      if(result != null) {
        window.location.reload();
      }
    })
  }

  function calculateDate(postedOn) {
    var options = { hour: '2-digit', minute: '2-digit', second: '2-digit', year: 'numeric', month: 'short', day: 'numeric' };
    var timeThen = parseInt(String(postedOn).replace('.', ""));
    var date = new Date(timeThen);
    return "(" + date.toLocaleDateString("en-GB", options) + ")";
  }

  function calculateTimeSince(postedOn) {
    var timeNow = parseInt(Date.now(), 10);
    var timeThen = parseInt(String(postedOn).replace('.', ""));
    var timeDiff = timeNow - timeThen;
    var seconds = parseInt(timeDiff / 1000.0, 10);
    
    if(seconds < 31556926) { // 1 year
      if(seconds < 2629743) { // 1 month
        if(seconds < 86400) { // 1 day
          if(seconds < 3600) { // 1 hour
            if(seconds < 60) { // 1 minute
              return "1 minute ago";
            } else {
              if(Math.floor(seconds / 60) === 1) {
                return "1 minute ago";
              } else return String(Math.floor(seconds / 60)) + " minutes ago";
            }
          } else { 
            if(Math.floor(seconds / 3600) === 1) {
              return "1 hour ago";
            } return String(Math.floor(seconds / 3600)) + " hours ago";
          }
        } else {
          if(Math.floor(seconds / 86400) === 1) {
            return "1 day ago";
          } return String(Math.floor(seconds / 86400)) + " days ago";
        }
      } else {
        if(Math.floor(seconds / 2629743) === 1) {
          return "1 month ago";
        } return String(Math.floor(seconds / 2629743)) + " months ago";
      }
    } else { 
      if(Math.floor(seconds / 31556926) === 1) {
        return "1 year ago";
      } return String(Math.floor(seconds / 31556926)) + " years ago";
    }
  }

  function compare(one, two) {
    if(one.postedOn < two.postedOn) return -1;
    if(one.postedOn > two.postedOn) return 1;
    return 0;
  }

  const array = [];
  if(props.data.comments) {
    props.data.comments.sort(compare).forEach(element => {

      array.push(
        <div className="comment__wrapper" key={element.id}>
          <div className="comment__author">{element.author.name}</div><span> · </span><div className="comment__date" id="time-since">{calculateTimeSince(element.postedOn)}</div> <div className="comment__date" id="date">{calculateDate(element.postedOn)}</div>
          <div className="comment__message">{element.message}</div>
        </div>
      )
    })
  }

  return(
    <div className="movie__big__container">
      <div className="movie__page__container">
        <div className="movie__page__wrapper--left">
          <h1 className="show-650">{props.data.title}</h1>
          <img src={`https://dtp.sergiudeaj.ro/assets/images/movies/${props.data.imageUrl}-400.jpg`} alt={props.data.title} width={270}/>
        </div>
        <div className="movie__page__wrapper--right">
          <h1 className="show-max">{props.data.title}</h1>
          <div className="info">
            <div><span style={{fontWeight: "700", color: "rgb(255, 162, 162)"}}>Rating: </span>{props.data.rating % 1 === 0 ? props.data.rating + ".0 " : props.data.rating + " "}<i className="fas fa-star" style={{fontSize: "0.95rem", color: "red"}}/></div>
            <div><span style={{fontWeight: "700", color: "rgb(255, 162, 162)"}}>Review: </span>{props.data.review}</div>
          </div>
        </div>
      </div>
      <div className="comments__container">
        <h2>Comments:</h2>
        <form className="comments__new-comment">
          <textarea className="comments__new-comment__textarea" placeholder="What are your thoughts?" onChange={event => {setCommentMessage(event.target.value)}}/>
          <button onClick={event => addComment(event)} className="comments__new-comment__button">COMMENT</button>
        </form>
        <div>{array}</div>
      </div>
    </div>
  )
}

export default Movie;
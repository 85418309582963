import RestClient from './rest-client'
import { HOST } from './hosts'

const endpoint = {
  getall:  '/api/movie/getAll',
  getbyid: '/api/movie/get/?id=',
  save: '/api/movie/add',
  delete: '/api/movie/delete/?id='
};

function getAll(callback) {
  let request = new Request(HOST.backend_api + endpoint.getall, {
    method: 'GET',
    headers : {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
  });
  RestClient.performRequest(request, callback);
}

function getById(id, callback) {
  let request = new Request(HOST.backend_api + endpoint.getbyid + id, {
    method: 'GET',
    headers : {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
  });
  RestClient.performRequest(request, callback);
}

function save(movie, callback) {
  let request = new Request(HOST.backend_api + endpoint.save, {
    method: 'POST',
    headers : {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
    },
    body: JSON.stringify(movie)
  });
  RestClient.performRequestNoJSON(request, callback);
}

function deleteById(id, callback) {
  let request = new Request(HOST.backend_api + endpoint.delete + id, {
    method: 'DELETE',
    headers : {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
    }
  });
  RestClient.performRequestNoJSON(request, callback);
}

export { getAll, getById, save, deleteById };
import React from 'react'
import jwt_decode from 'jwt-decode'

import './Header.scss'
import * as API from '../../api/users-api'

function Header(props) {

  function handleLogout() {
    let refreshToken = {
      refreshToken: props.refreshToken,
    }
    API.logoutUser(refreshToken);
    props.removeRefreshToken();
    localStorage.removeItem('accessToken');
    props.setLoggedIn(false);
  }

  const logoutButtonsStyle = {
    display: props.loggedIn ? 'block' : 'none',
  }

  const loginButtonsStyle = {
    display: props.loggedIn ? 'none' : 'block',
  }

  const hiddenButtonStyle = { 
    display: `${localStorage.getItem('accessToken') != null ? 'block' : 'none'}`
  }

  const showStyle = {
    display: `${localStorage.getItem('accessToken') != null && jwt_decode(localStorage.getItem('accessToken')).admin ? 'block' : 'none'}`,
    marginRight: '15px'
  }

  const hideStyle = {
    display: 'none',
    marginRight: '0px'
  }

  return(
    <header>
      <nav>
        <ul className="nav-list">
          <li><a href="/">Home</a></li>
        </ul>

        <ul className="nav-list">
          {/* <li style={hiddenButtonStyle}><a href="/movies">Movies</a></li>
          <li style={hiddenButtonStyle}><a href="/series">Series</a></li>
          <li style={hiddenButtonStyle}><a href="/anime">Anime</a></li> */}
          <li><a href="/movies">Movies</a></li>
          <li><a href="/series">Series</a></li>
          <li><a href="/anime">Anime</a></li>
        </ul>

        <ul className="nav-list">
          <li style={!props.showAdmin ? showStyle : hideStyle}><button className="admin-button" onClick={() => props.toggleShowAdmin()}>Show Admin</button></li>
          <li style={props.showAdmin ? showStyle : hideStyle}><button className="admin-button" onClick={() => props.toggleShowAdmin()}>Hide Admin</button></li>
          <li style={loginButtonsStyle}><a href="/login">Login</a></li>
          <li style={logoutButtonsStyle}><a href="/logout" onClick={handleLogout}>Logout</a></li>
        </ul>
      </nav>
    </header>
  )
}

export default Header;
import * as API_USER from './users-api'

var refreshToken = "";

function setRefreshToken(newRefreshToken)  {
  refreshToken = newRefreshToken;
}

function performRequest(request, callback) {
  fetch(request)
    .then(
      function(response) {
        if(response.ok) {
          response.json().then(json => callback(json));
        } else {
          if(response.status === 401) {
            if (refreshToken !== "") {
              let refreshT = {
                refreshToken: `${refreshToken}`
              }
              API_USER.refreshToken(refreshT, result => {
                if(result != null) {
                  localStorage.setItem('accessToken', result.accessToken);
                  callback(response);
                }
              })
            }
          }
        }
      }
    )
    .catch(function (err) {
      callback(err)
    });
}

function performRequestNoJSON(request, callback) {
  console.log(request);
  fetch(request)
    .then(
      function(response) {
        console.log(response);
        console.log(response.message);
        if(response.ok) {
          response.text().then(text => callback && callback(text));
        } else {
          if(response.status === 401) {
            if (refreshToken !== "") {
              let refreshT = {
                refreshToken: `${refreshToken}`
              }
              API_USER.refreshToken(refreshT, result => {
                if(result != null) {
                  localStorage.setItem('accessToken', result.accessToken);
                  callback(response);
                }
              })
            }
          }
        }
      }
    )
    .catch(function (err) {
      callback(err)
    });
}

export default { performRequest, performRequestNoJSON, setRefreshToken }
import RestClient from './rest-client'
import { HOST } from './hosts'

const endpoint = {
  login: '/api/auth/login',
  logout: '/api/auth/logout',
  register: '/api/auth/register',
  refreshToken: '/api/auth/refreshToken',
}

function loginUser(user, callback) {
  let request = new Request(HOST.backend_api + endpoint.login, {
    method: 'POST',
    headers : {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(user)
  });
  RestClient.performRequest(request, callback);
}

function logoutUser(refreshToken, callback) {
  let request = new Request(HOST.backend_api + endpoint.logout, {
    method: 'POST',
    headers : {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(refreshToken)
  });
  RestClient.performRequest(request, callback);
}

function registerUser(user, callback) {
  let request = new Request(HOST.backend_api + endpoint.register, {
    method: 'POST',
    headers : {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(user)
  });
  RestClient.performRequest(request, callback);
}

function refreshToken(refreshToken, callback) {
  let request = new Request(HOST.backend_api + endpoint.refreshToken, {
    method: 'POST',
    headers : {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(refreshToken)
  });
  RestClient.performRequest(request, callback);
}

export  { loginUser, logoutUser, registerUser, refreshToken }
import RestClient from './rest-client'
import { HOST } from './hosts'

const endpoint = {
  save: '/api/comment/add',
};

function save(comment, callback) {
  let request = new Request(HOST.backend_api + endpoint.save, {
    method: 'POST',
    headers : {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(comment)
  });
  RestClient.performRequestNoJSON(request, callback);
}

export { save };
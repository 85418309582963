import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { Redirect } from 'react-router-dom'

import './Content.scss'
import HomePage from './HomePage/HomePage'
import MoviePage from './MoviePage/MoviePage'
import LoginPage from './LoginPage/LoginPage'

function Content(props) {

  function redirectToHome() {
    return <Redirect to="/" />
  }

  function moviesPageFunction() {
    if(localStorage.getItem('accessToken') != null) {
      return <MoviePage refreshToken={props.refreshToken} showAdmin={props.showAdmin}/> 
    } 
    return <Redirect to="/" />
  }

  return(
    <main>
      <Router>
        <Switch>
          <Route exact path='/' render={() => 
            <HomePage
              isLoggedIn = { props.isLoggedIn }
              refreshToken = { props.refreshToken }            
            />}
          />
          <Route exact path='/login' render={() => 
            <LoginPage
              setLoggedIn = { props.setLoggedIn }
              refreshToken = { props.refreshToken }
              setRefreshToken = { props.setRefreshToken }
            />}
          /> 
          <Route exact path='/logout' render={() => redirectToHome()}/>
          {/* <Route exact path='/movies' render={() => moviesPageFunction()}/> */}
          <Route exact path='/movies' render={() => <MoviePage refreshToken={props.refreshToken} showAdmin={props.showAdmin}/>}/>
        </Switch>
      </Router>

      
    </main>
  )
}

export default Content;
import React, { useState, useEffect, useRef } from 'react'
import * as API from '../../../api/movies-api'
import jwt_decode from 'jwt-decode'

import './Movies.scss'
import MoviePopup from './MoviePopup'

function Movies(props) {
  
  const [data, setData] = useState([]);
  const [popupData, setPopupData] = useState([]);
  const [showPopup, setShowPopup] = useState(false);

  function deleteById(event, id) {
    event.preventDefault();
    API.deleteById(id, result => {
      if(result != null) {
        if(result.status === 401) {
          setTimeout(() => { API.deleteById(id, res => {
            if(res != null && res.status != 401) {
              window.location.reload();
            }
          }) }, 1000);
        } else window.location.reload();
      } else window.location.reload();
    })
  }

  function compare(one, two) {
    if(one.watchedOn < two.watchedOn) return 1;
    if(one.watchedOn > two.watchedOn) return -1;
    return 0;
  }

  function fetch() {
    return API.getAll(result => {
      if(result != null)  {
        var array = [];
        if(Array.isArray(result)) {
          result.forEach(element => {
            array.push(element);
          });
        }
        array.sort(compare)
        setData(array);
      }
    });
  }

  useEffect(fetch, []);

  const elements = [];
  data.forEach(element => {
    elements.push(
      <div className="movie__card" key={element.id} onClick={() => {setPopupData(element); setShowPopup(true); window.scrollTo(0, 0);}}>
        <div><img src={`https://dtp.sergiudeaj.ro/assets/images/movies/${element.imageUrl}-200.jpg`} alt={element.title}/></div>
        <div className="info">
          <p><i className="fas fa-star"></i> {element.rating % 1 === 0 ? element.rating + ".0" : element.rating}</p>
          <div><h4 className={`title--${element.title.length > 23 ? 'twoliner' : 'oneliner'}`}>{element.title}</h4></div>
        </div>
        <button className="circle__button" onClick={event => {deleteById(event, element.id)}} style={{display: `${props.showAdmin && localStorage.getItem('accessToken') != null && jwt_decode(localStorage.getItem('accessToken')).admin ? 'block' : 'none'}`}}><i className="fas fa-times"></i></button>
      </div>
    );
  });

  return(
    <>
      {elements}
      <MoviePopup showPopup={showPopup} setShowPopup={setShowPopup} data={popupData}/>
    </>
  )
}

export default Movies;
import React, { useState } from 'react'
import {withRouter} from 'react-router-dom'

import './LoginPage.scss'
import * as API  from '../../../api/users-api'

function LoginPage(props) {

  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")

  function handleLogin(event) {
    event.preventDefault()
    let user = {
      email: username.trim(),
      password: password.trim(),
    }
    API.loginUser(user, result => {
      if(result != null) {
        console.log(result);
        props.setRefreshToken(result.refreshToken);
        localStorage.setItem('accessToken', result.accessToken);
        props.setLoggedIn(true);
        props.history.push('/');
      }
    });
  }

  return (
    <form className="login-form">
      <h1 className="login-form__title">Login</h1>
      <div className="login-form__wrapper">
        <label className="login-form__label">EMAIL ADDRESS</label>
        <input className="login-form__input" placeholder="john.smith@gmail.com" type="text" onChange={e => {setUsername(e.target.value)}}/>
      </div>
      <div className="login-form__wrapper">
        <label className="login-form__label">PASSWORD</label>
        <input className="login-form__input" placeholder="A30%c25K@" type="password" onChange={e => {setPassword(e.target.value)}}/>
      </div>
      <button className="login-form__button" onClick={event => {handleLogin(event)}}>Sign In</button>
    </form>
  )
}

export default withRouter(LoginPage);